<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.notifications')"
        :pgIcon="'bx bx-bell'"
        :refs="refs"
        :addNew="addNew"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8">
          <div class="card">
            <div class="card-body">
              <h4
                class="card-title"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
              >
                {{ $t("view.title") }} ( {{ title_used }} / {{ title_limit }} )
              </h4>
              <div class="tab-danger">
                <input
                  type="text"
                  class="form-control"
                  v-model="title"
                  v-on:keyup="onkeyupTitle"
                  :maxlength="title_limit"
                />
              </div>
              <br /><br />
              <h4
                class="card-title"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
              >
                {{ $t("app.notify_msg") }} ( {{ body_used }} /
                {{ body_limit }} )
              </h4>

              <div class="tab-danger">
                <textarea
                  v-model="body"
                  rows="9"
                  v-on:keyup="onkeyupBody"
                  :maxlength="body_limit"
                  class="form-control"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!pgLoading" class="col-4">
          <CardLecturer
            v-if="auth.role == 'root'"
            :lecturer_id="row.lecturer_id"
            v-on:lecturerChange="handleLecturerChange"
          >
          </CardLecturer>

          <div v-if="row.lecturer_id" class="card" style="padding: 10px">
            <div class="col-md-12">
              <h4
                class="card-title"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                {{ $t("app.choose_module") }}
              </h4>

              <ModelSelect
                v-if="!pgLoading"
                :options="modules"
                v-model="row.model_type"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
                :placeholder="$t('app.choose_module')"
                @input="fetchModule"
                :style="$i18n.locale == 'ar' ? 'text-align: right' : ''"
              >
              </ModelSelect>
            </div>

            <div
              v-if="hasChild"
              class="col-md-12 text-center"
              style="margin-top: 10px"
            >
              <span
                v-if="dataLoading"
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              >
              </span>

              <ModelSelect
                v-if="!dataLoading"
                :options="dataModelSelect"
                v-model="row.model_id"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
                placeholder=""
                :style="$i18n.locale == 'ar' ? 'text-align: right' : ''"
              >
              </ModelSelect>

              <div
                v-if="!dataLoading"
                :style="
                  $i18n.locale == 'ar'
                    ? 'text-align: right; padding:2px'
                    : 'text-align: left; padding:2px'
                "
              >
                <i
                  style="color: red"
                  v-if="
                    row.model_type == 'onlineCourses' ||
                    row.model_type == 'offlineCourses'
                  "
                >
                  {{ $t("app.notes_to_course_members") }}
                </i>
                <i style="color: red" v-if="row.model_type == 'books'">
                  {{ $t("app.notes_to_book_members") }}
                </i>
              </div>
            </div>
          </div>

          <div
            v-if="row.model_type == 'members'"
            class="card"
            style="height: 400px"
          >
            <div class="card-body">
              <h4
                class="card-title"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
              >
                {{ $t("app.choose_members") }}
              </h4>
              <br />
              <p :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''">
                &nbsp;&nbsp;&nbsp;
                <input type="checkbox" @change="clickAll" /> &nbsp;&nbsp;
                {{ $t("app.all") }}
              </p>
              <div
                class="tab-danger pt-3"
                style="height: 300px; overflow-x: hidden; overflow-y: auto"
              >
                <table class="table table-striped">
                  <tbody>
                    <tr v-for="(user, idx) in users" :key="idx">
                      <td style="width: 10%">
                        <input
                          type="checkbox"
                          @change="isChecked($event, user.id)"
                          :id="user.id"
                          :value="user.id"
                          :checked="
                            selected_ids.indexOf(user.id) !== -1 ? true : ''
                          "
                        />
                      </td>
                      <td>
                        <img
                          :src="user.image ? user.image.url : ''"
                          style="width: 30px; height: 30px; border-radius: 50%"
                        />
                        {{ user.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { ModelSelect } from "vue-search-select";

export default {
  name: "List",
  components: {
    ModelSelect,
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
    Loading: () => import("@/components/Loading.vue"),
    CardLecturer: () => import("@/components/CardLecturer.vue"),
    Buttons: () => import("@/components/Buttons.vue"),
  },
  data() {
    return {
      //
      addNew: false,

      title_used: 0,
      body_used: 0,
      title_limit: 65,
      body_limit: 240,

      title: "",
      body: "",

      all: 0,
      selected_ids: [],
      users_ids: [],

      // auth
      auth: {
        role: "",
        access_token: "",
      },

      methodType: "POST",
      pgLoading: false,
      dataLoading: false,
      btnCurrent: "Send",
      btnLoading: false,

      users: [],
      refs: "notifications",

      row: {
        model_id: "",
        model_type: "",
        lecturer_id: "",
      },
      modules: [
        {
          text: this.$t("nav." + "books"),
          value: "books",
        },
        {
          text: this.$t("nav." + "onlineCourses"),
          value: "onlineCourses",
        },
        {
          text: this.$t("nav." + "offlineCourses"),
          value: "offlineCourses",
        },
        {
          text: this.$t("app." + "choose_members"),
          value: "members",
        },
      ],

      data: [],
      dataModelSelect: [],
      hasChild: false,
    };
  },
  watch: {},
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
      if (this.auth.role == "lecturer" && localStorage.getItem("lecturer_id")) {
        this.row.lecturer_id = localStorage.getItem("lecturer_id");
      }
    }

    this.fetchMembers();
  },
  methods: {
    //
    fetchMembers() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/users?paginate=10000",
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.users = res.data.data.rows;
          for (let i = 0; i < this.users.length; i++) {
            this.users_ids.push(res.data.data.rows[i].id);
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.pgLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    fetchModule() {
      this.fetchData();
    },

    fetchData() {
      const array = ["books", "onlineCourses", "offlineCourses"];
      this.hasChild = false;
      if (!array.includes(this.row.model_type)) {
        this.row.model_id = "";
      }
      if (array.includes(this.row.model_type)) {
        this.hasChild = true;
        this.dataLoading = true;
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.row.model_type,
          method: "GET",
          data: {},
          params: {
            status: "active",
            paginate: 1000,
            lecturer_id: this.row.lecturer_id,
          },
        };
        this.axios(options)
          .then((res) => {
            this.dataLoading = false;
            this.data = res.data.data.rows;
            // transform to modelSelect
            this.dataModelSelect = [];
            this.data.forEach((element) => {
              this.dataModelSelect.push({
                value: element["id"],
                text: element[this.$i18n.locale]["title"],
              });
            });
          })
          .catch((err) => {
            this.dataLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          });
      }
    },

    // all
    clickAll() {
      if (this.all == 0) {
        this.all = 1;
        for (let i = 0; i < this.users.length; i++) {
          this.selected_ids.push(this.users[i].id);
        }
      } else {
        this.all = 0;
        this.selected_ids = [];
      }
    },

    // is Checked
    isChecked($event, id) {
      if ($event.target.checked) {
        this.selected_ids.push(id);
      } else {
        this.selected_ids.splice(this.selected_ids.indexOf(id), 1);
      }
    },

    // submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/" + this.refs,
        method: this.methodType,
        data: {
          title: this.title,
          body: this.body,
          model_id: this.row.model_id,
          model_type: this.row.model_type,
          selected_ids: this.selected_ids,
          lecturer_id: this.row.lecturer_id,
        },
      };
      console.log(options);
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم إرسال الرسالة بنجاح",
          });
          setTimeout(() => this.$router.go(this.$router.currentRoute), 1500);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: "dashboard" });
      }
    },

    //
    onkeyupTitle(evt) {
      console.log(evt);
      this.title_used = this.title.length;
    },
    //
    onkeyupBody(evt) {
      console.log(evt);
      this.body_used = this.body.length;
    },

    //
    handleLecturerChange(event) {
      this.row.lecturer_id = event.input;
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
